import React from 'react'

import Greener from './HomePages/Greener';
import Product from './HomePages/Product';
import Abouthome from './HomePages/Abouthome';
import Homepage2 from './Homepage2';
import Service from './HomePages/Service';

function Home() {
  return (
    <>
       <Homepage2/>
       <Abouthome/>
       <Service/>

       <Greener/>
       <Product/>
    </>
  )
}

export default Home;