import React from 'react';
import infra1 from '../../img/infra1.JPG';
import infra2 from '../../img/infra2.JPG';
import infra3 from '../../img/infra3.JPG';
import infra4 from '../../img/infra4.JPG';
import bgimage from '../../img/packaging_innovations_blue__graph_grid.jpg';
import strnght1 from '../../img/Strengh1 (1).jpg';
import strnght2 from '../../img/Strengh1 (2).jpg';

function CompanyProfile() {
  return (
    <div className=''>
      <div className='container mx-auto px-4 py-12 animate-slideInRight'>
        <h2 className='text-3xl font-bold text-center mb-8'>Strength</h2>
        <div className="flex h-1 mb-8 w-96 mx-auto bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02]"></div>
        <hr className='border-t-2 border-gray-300 mb-8' />
        <div className='flex flex-col md:flex-row justify-center items-center gap-4'>
          <img src={strnght1} className='w-full h-96 md:w-1/2 rounded-lg shadow-md' alt='Strength 1' />
          <img src={strnght2} className='w-full h-96 md:w-1/2 rounded-lg shadow-md' alt='Strength 2' />
        </div>
        <div
          style={{ backgroundImage: `url(${bgimage})` }}
          className='p-8 rounded-lg shadow-lg mt-8 bg-cover bg-center'>
          <p className='text-lg mb-4 text-gray-800 font-medium '>
            At Maruti Rotoprinters, our strength lies in our commitment to innovation and excellence in packaging solutions. We aim to provide "one notch up" packaging solutions that align with the latest market trends.
          </p>
          <p className='text-lg mb-4 text-gray-800 font-medium'>
            Our dedicated in-house Research & Development team constantly works to innovate our products and processes. We prioritize market and customer needs, conducting thorough research to deliver trending designs that ensure ease of handling and top-notch product safety.
          </p>
          <p className='text-lg mb-4 text-gray-800 font-medium'>
            Our commitment to the environment is evident in our support for eco-friendly packaging formats, using the best materials without compromising on quality.
          </p>
        </div>
      </div>

      <div className='container mx-auto px-4 py-12 animate-slideInLeft'>
        <h2 className='text-3xl font-bold text-center mb-8'>Infrastructure</h2>
        <div className="flex h-1 mb-8 w-96 mx-auto bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02]"></div>
        <hr className='border-t-2 border-gray-300 mb-8' />
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'>
          <img src={infra1} className='w-full rounded-lg shadow-md' alt='Infrastructure 1' />
          <img src={infra2} className='w-full rounded-lg shadow-md' alt='Infrastructure 2' />
          <img src={infra3} className='w-full rounded-lg shadow-md' alt='Infrastructure 3' />
          <img src={infra4} className='w-full rounded-lg shadow-md' alt='Infrastructure 4' />
        </div>
        <div
          style={{ backgroundImage: `url(${bgimage})` }}
          className='p-8 rounded-lg shadow-lg mt-8 bg-cover bg-center'>
          <p className='text-lg mb-4 text-gray-800 font-medium'>
            At Maruti Rotoprinters, we pride ourselves on our state-of-the-art manufacturing facility nestled in the vibrant city of Sangli. Our cutting-edge infrastructure is certified by the British Retail Council (BRC), ensuring top-notch quality and compliance with international standards.
          </p>
          <p className='text-lg mb-4 text-gray-800 font-medium'>
            Our commitment to innovation and quality is evident in our continual expansion and enhancement efforts. Future-ready and equipped with the latest technology, we are proud of our dedication to sustainable and responsible manufacturing practices.
          </p>
          <p className='text-lg text-gray-800 font-medium'>
            Experience the pinnacle of manufacturing excellence with Maruti Rotoprinters, where quality, innovation, and sustainability converge.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
