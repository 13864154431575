import React from 'react';
import img1 from '../../../img/Product1.png';
import img2 from '../../../img/Product2.png';
import img3 from '../../../img/Product3.png';
import img4 from '../../../img/Product4.png';
import img5 from '../../../img/Product5.png';
import img6 from '../../../img/Product6.png';

function Product() {
    const products = [
        {
            image: img1,
            head: 'CHEMICAL INDUSTRY'
        },
        {
            image: img2,
            head: 'COSMETICS'
        },
        {
            image: img3,
            head: 'FOOD & NUTRITION'
        },
        {
            image: img4,
            head: 'HOME & GARDEN'
        },
        {
            image: img5,
            head: 'PET FOOD'
        },
        {
            image: img6,
            head: 'PHARMACEUTICALS/MEDICAL'
        },
    ];

    return (
        <div className='container mx-auto p-4 mt-10'>
            <h1 className='text-4xl font-bold text-center mb-8 text-black'>Industries We Work With</h1>
            <div class="flex h-1 w-48 md:w-96 m-20 md:m-0 md:mb-8 bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02] md:mx-auto"></div>       
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
                {products.map((item, index) => (
                    <div className='bg-white flex flex-col items-center p-4 ' key={index}>
                        <img src={item.image} alt={item.head} className='w-28 h-28 object-cover mb-4 sm:w-36 sm:h-36 md:w-44 md:h-44' />
                        <h2 className='text-xl font-semibold text-center text-black mt-4'>{item.head}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Product;
