import React from 'react';
import img1 from '../../../img/Emerging-biomaterials.svg';
import img2 from '../../../img/Industrially-compostable-packaging.svg';
import img3 from '../../../img/High-performance-materials.svg';
import img4 from '../../../img/Recyclable-Polyethylene.svg';
import bgimage from '../../../img/background.jpg';

function Greener() {
  return (
    <div>
      <div className="container mx-auto text-center  mt-10">
        <h1 className="text-4xl font-bold mb-6">
          Sustainable Packaging Solutions for a Greener Tomorrow
        </h1>
        <div class="flex h-1 w-48 md:w-96 m-20 md:m-0 md:mb-8 bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02] md:mx-auto"></div>       

        <p className="text-lg mb-12">
          At Marutiroto Printers, we're committed to providing sustainable packaging solutions that meet your needs while supporting a greener tomorrow. Contact us today to learn more about our food-grade packaging solutions.
        </p>
      </div>
      <div className=" grid gap-8 sm:grid-cols-2 lg:grid-cols-4 px-4">
        <div className=" p-6 rounded-lg shadow-lg text-left transform transition hover:scale-105">
          <img src={img1} className="w-20 h-20 mb-4" alt="Recycled Content Packaging" />
          <h2 className="text-2xl font-semibold mb-2 text-left">Innovative Design</h2>
          <p className="text-gray-700">
          Our packaging is designed to reduce waste, optimize space, and lower carbon footprints, all while maintaining the highest standards of quality and durability          </p>
        </div>
        <div className=" p-6 rounded-lg shadow-lg text-left transform transition hover:scale-105">
          <img src={img2} className="w-20 h-20 mb-4" alt="Curbside Recyclable Packaging" />
          <h2 className="text-2xl font-semibold mb-2 text-left">Curbside Recyclable Packaging</h2>
          <p className="text-gray-700">
            Our packaging solutions are designed to be recyclable from the comfort of your own home. Our curbside recyclable packaging ensures that our pouches and rolls can be easily collected and recycled, reducing landfill waste and promoting sustainability.
          </p>
        </div>
        <div className="p-6 rounded-lg shadow-lg text-left transform transition hover:scale-105">
          <img src={img3} className="w-20 h-20 mb-4" alt="Compostable Packaging" />
          <h2 className="text-2xl font-semibold mb-2 text-left"> Food-Grade Assurance</h2>
          <p className="text-gray-700">
          Safety is paramount. Our food-grade packaging solutions ensure that your products remain fresh, secure, and compliant with all health and safety regulations.          </p>
        </div>
        <div className=" p-6 rounded-lg shadow-lg text-left transform transition hover:scale-105">
          <img src={img4} className="w-20 h-20 mb-4" alt="Recyclable Polyethylene Packaging" />
          <h2 className="text-2xl font-semibold mb-2 ">Recyclable Polyethylene Packaging</h2>
          <p className="text-gray-700">
            Our recyclable polyethylene packaging solutions offer a durable and sustainable way to package your products. Our polyethylene packaging is designed to be recyclable, reducing waste and supporting a more circular economy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Greener;
