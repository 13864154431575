import React from 'react';
import bgimg from '../../img/infra2.JPG';
import img from '../../img/contact-us-vector-1024x754-1.png';
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';

function Contact() {
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const phone = formData.get('phone');
    const message = formData.get('message');

    const whatsappMessage = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nMessage: ${message}`;

    const whatsappUrl = `https://wa.me/919730586756?text=${encodeURIComponent(whatsappMessage)}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      className='relative min-h-screen flex items-center justify-center'
    >
      <div className='bg-opacity-70 bg-gray-800 p-6 sm:p-10 rounded-lg shadow-lg w-full mx-auto text-white'>
        <h1 className='text-white font-bold text-2xl sm:text-4xl text-center mb-6 sm:mb-10'>Contact Us</h1>
        <div className="flex h-1 mb-6 sm:mb-8 w-24 sm:w-96 mx-auto bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02]"></div>

        <div className='grid lg:grid-cols-2 items-center gap-8 sm:gap-20'>
          <div className='space-y-8 sm:space-y-12'>
            <div className='flex items-center space-x-4 sm:space-x-6'>
              <div className='bg-gray-100 p-3 sm:p-4 rounded-full shadow-lg'>
                <MdLocalPhone className='text-red-500 text-xl sm:text-2xl' />
              </div>
              <div>
                <h3 className='text-md sm:text-lg font-bold text-start'>Call</h3>
                <p className='text-lg sm:text-xl font-bold'>+91 97305 86756</p>
              </div>
            </div>
            <div className='flex items-center space-x-4 sm:space-x-6'>
              <div className='bg-gray-100 p-3 sm:p-4 rounded-full shadow-lg'>
                <MdOutlineMail className='text-red-500 text-xl sm:text-2xl' />
              </div>
              <div>
                <h3 className='text-md sm:text-lg font-bold text-start'>Email</h3>
                <p className='text-lg sm:text-xl font-bold'>marutirotoprinters@gmail.com</p>
              </div>
            </div>
            <img src={img} alt="Contact Us" className='w-full ' />
          </div>
          <div className='relative'>
            <h1 className='text-white text-2xl sm:text-3xl font-bold italic'>Contact Us!</h1>
            <p className='text-white text-md sm:text-lg font-semibold italic pt-2'>Get in touch and let us know how we can help.</p>
            <form className="text-md sm:text-lg text-white italic pt-5" onSubmit={handleSubmit}>
              <input
                name="name"
                className="w-full h-10 text-base text-white bg-transparent outline-none p-1 mt-2 mb-4 border-b-2  placeholder-white"
                placeholder="Name"
                type="text"
                required
              />
              <input
                name="email"
                className="w-full h-10 text-base text-white bg-transparent outline-none p-1 mt-2 mb-4 border-b-2  placeholder-white"
                placeholder="Email"
                type="email"
                required
              />
              <input
                name="phone"
                className="w-full h-10 text-base text-white bg-transparent outline-none p-1 mt-2 mb-4 border-b-2  placeholder-white"
                placeholder="Phone"
                type="tel"
                required
              />
              <input
                name="message"
                className="w-full h-10 text-base text-white bg-transparent outline-none p-1 mt-2 mb-4 border-b-2  placeholder-white"
                placeholder="Message"
                type="text"
                required
              />
              <div className="text-center py-6">
                <button
                  type="submit"
                  className="w-32 sm:w-44 h-9 px-1 py-1 rounded-full bg-[#0a78df] text-white hover:shadow-md hover:shadow-[#0a78dfdc] border-none"
                >
                  Submit
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
