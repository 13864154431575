import React from 'react';
import p1 from '../../img/p1.png';
import p2 from '../../img/p2.png';
import p3 from '../../img/p3.png';
import p4 from '../../img/p4.png';
import p5 from '../../img/p5.png';
import p6 from '../../img/p6.png';
import p7 from '../../img/p7.png';
import p8 from '../../img/p8.png';
import p9 from '../../img/p9.png';
import p10 from '../../img/p10.png';
import p11 from '../../img/p11.png';
import p12 from '../../img/p12.png';
import p13 from '../../img/p13.png';

function Product() {
    const products = [
        { image: p1, title: 'Namkeen, Snacks & Chips' },
        { image: p2, title: 'Biscuits & Confectionery' },
        { image: p3, title: 'Tea & Coffee' },
        { image: p4, title: 'Atta & Groceries' },
        { image: p5, title: 'Dairy & Ice Cream' },
        { image: p6, title: 'Edible Oil' },
        { image: p7, title: 'Pet Food' },
        { image: p8, title: 'Sea Food & Vacuum Pouches' },
        { image: p9, title: 'Seed & Fertilizer' },
        { image: p10, title: 'Cosmetics' },
        { image: p11, title: 'Detergent & Chemical' },
        { image: p12, title: 'Incense Sticks' },
        { image: p13, title: 'Pharma Products' }
    ];

    return (
        <div className=''>
            <div className='container mx-auto px-4 py-8'>
                <h1 className='text-4xl font-bold text-center text-gray-800 mb-8'>OUR PRODUCTS</h1>
                <p className='text-center text-lg text-gray-700 mb-8'>We manufacture high-quality custom printed flexible packaging materials as multi-layer roll stock films and pouches for aseptic packaging of food products.</p>
                <div class="flex h-1 w-48 md:w-96 m-20 md:m-0 md:mb-8 bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02] md:mx-auto"></div>       
                         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {products.map((product, index) => (
                        <div key={index} className="bg-[#F9DECD]   rounded-lg overflow-hidden shadow-lg">
                            <div className="relative">
                                <img className="w-full h-64 object-cover" src={product.image} alt={product.title} />
                                <div className="absolute top-0 right-0 bg-black text-white p-2 rounded-bl">
                                    <svg className="fill-current text-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 3l-7 9h14z"/></svg>
                                </div>
                            </div>
                            <div className="px-6 py-4 bg-white">
                                <div className="font-bold text-xl mb-2 text-center">{product.title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Product;
