import React from 'react';
import Service1Image from '../../../img/SNACKS (1).jpg';
import Service2Image from '../../../img/BISCUITS (1).jpg';
import Service3Image from '../../../img/TEA_AND_COFFEE (1).jpg';
import Service4Image from '../../../img/GROCERY (1).jpg';
import { Link } from 'react-router-dom';

function Service() {
    const products = [
        { image: Service1Image, title: 'Snacks & Chips' },
        { image: Service2Image, title: 'Biscuits & Confectionery' },
        { image: Service3Image, title: 'Tea & Coffee' },
        { image: Service4Image, title: 'Atta & Groceries' }
    ];

    return (
        <div className='container mx-auto px-4 py-8'>
            <h1 className='text-4xl font-bold text-center text-gray-800 mb-8'>OUR PRODUCTS</h1>
            <p className='text-center text-lg text-gray-700 mb-8'>We manufacture high-quality custom printed flexible packaging materials as multi-layer roll stock films and pouches for aseptic packaging of food products.</p>
            <div class="flex h-1 w-48 md:w-96 m-20 md:m-0 md:mb-8 bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02] md:mx-auto"></div>       
      
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {products.map((product, index) => (
                    <div key={index} className="rounded-lg overflow-hidden shadow-lg">
                        <div className="relative">
                            <img className="w-full h-64 object-cover" src={product.image} alt={product.title} />
                            <div className="absolute top-0 right-0 bg-black text-white p-2 rounded-bl">
                                <svg className="fill-current text-white h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 3l-7 9h14z"/></svg>
                            </div>
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2 text-center">{product.title}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-center mt-8">
                <Link to="/product">
                    <button className="w-40 py-2 text-center text-white bg-blue-700 rounded-full shadow-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                        See More
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Service;
