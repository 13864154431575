import React from 'react';
import bgimage from '../../img/Aboutback.png';
import bgimage2 from '../../img/packaging_innovations_blue__graph_grid.jpg';

import { FaBoxesPacking } from "react-icons/fa6";
import { MdHighQuality } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { IoTimeSharp } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiPriceTag2Fill } from "react-icons/ri";
import { FaChartLine } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";

function About() {
  const aboutData = [
    {
      heading: 'Customized Packaging Solutions',
      icon: <FaBoxesPacking />
    },
    {
      heading: 'Quality Assurance',
      icon: <MdHighQuality />
    },
    {
      heading: 'Innovative and Sustainable Practices',
      icon: <GrWorkshop />
    },
    {
      heading: 'Timely Delivery',
      icon: <IoTimeSharp />
    },
    {
      heading: 'Excellent Customer Support',
      icon: <MdOutlineSupportAgent />
    },
    {
      heading: 'Competitive Pricing',
      icon: <RiPriceTag2Fill />
    },
    {
      heading: 'Experience and Expertise',
      icon: <FaChartLine />
    },
    {
      heading: 'Reputation and Reliability',
      icon: <AiFillStar />
    }
  ];

  return (
    <div className='min-h-screen  pt-10'>
      <h1 className='text-4xl font-bold text-center py-8'>About Us</h1>
      <div className="flex h-1 mb-8 w-96 mx-auto bg-gradient-to-r from-black via-[#EB0186] via-[#02ACEA] to-[#FECA02]"></div>
      <div
        className="bg-cover bg-center py-12"
        style={{ backgroundImage: `url(${bgimage})` }}
      >
        <div className="container mx-auto text-white px-4 md:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {aboutData.map((item, index) => (
              <div key={index} className="flex flex-col items-center bg-gray-900 bg-opacity-75 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="text-5xl mb-4 text-yellow-400">
                  {item.icon}
                </div>
                <h2 className="text-2xl font-semibold text-center">
                  {item.heading}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='container mx-auto px-4 py-12'>
        <div className=' p-8 rounded-lg shadow-lg'
                  style={{ backgroundImage: `url(${bgimage2})` }}
                  >
          <p className='text-lg mb-4 font-medium'>
            <span className='font-bold text-2xl text-[#01ABEF]'>Welcome to Maruti Rotoprinters!</span><br/>
            Established in 2024, we specialize in manufacturing high-quality rotogravure printed laminated pouches and rolls for packaging.
          </p>
          <p className='text-lg mb-4 font-medium'>
            We cater to a diverse range of industries, producing all types of Food-grade and General-purpose flexible packaging solutions.
            Maruti Rotoprinters are associated with various reputed names in the Food and Food-Packaging industries as well as provide the packaging materials for some of the State Government's departments.
          </p>
          <p className='text-lg mb-4 font-medium'>
            Founded by Pranil Mehta and Pritesh Saundatte, Maruti Rotoprinters brings together a team of highly educated professionals with extensive experience in the packaging industry.
          </p>
          <p className='text-lg font-medium'>
            Discover the difference with Maruti Rotoprinters – where quality meets innovation.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
