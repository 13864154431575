import React from 'react'
import Slider from 'react-slick'
import img1 from '../../img/Blog-Post-Feature-Image-1-1.png'
import img2 from '../../img/Blog-Post-Feature-Image.png'
import img3 from '../../img/Blog-Post-Feature-Image-1.png'
import logo from '../../img/Logo_maruti_rotoprinter-removebg-preview.png'
import pack from '../../img/Pi7_Tool_Flexible-Packaging-Group-3-removebg-preview.png';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './Homepage2.css';

function Homepage2() {
    const homeImages = [img1, img2, img3]

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    }

    return (
        <div className='flex flex-col md:flex-row h-screen'>
            <div className='w-full md:w-3/4 relative'>
                <Slider {...sliderSettings} className='h-full'>
                    {homeImages.map((img, index) => (
                        <div key={index} className='h-full'>
                            <img src={img} alt={`Slide ${index + 1}`} className='w-full h-full object-cover' />
                        </div>
                    ))}
                </Slider>
                <div className='hidden md:block absolute bottom-11 left-5 md:left-20 z-20'>
                    <h1 className='text-xl md:text-2xl text-slate-200 px-4 py-1 font-medium bg-yellow-500 rounded-t-lg animate-slide-in-left'>Request Quote</h1>
                    <form className='w-full sm:w-auto flex flex-col items-center sm:flex-row text-slate-700 tracking-wider font-semibold py-4 bg-black bg-opacity-40'>
                        <input className='w-72 md:w-44 lg:w-52 h-10 px-6 py-2 sm:mx-2 lg:mx-6 my-3 sm:my-0 rounded-md' placeholder='Your Name' type='text' />
                        <input className='w-72 md:w-44 lg:w-52 h-10 px-6 py-2 sm:mx-2 lg:mx-6 my-3 sm:my-0 rounded-md' placeholder='Your E-mail' type='email' />
                        <input className='w-72 md:w-44 lg:w-52 h-10 px-6 py-2 sm:mx-2 lg:mx-6 my-3 sm:my-0 rounded-md' placeholder='Your Phone' type='tel' />
                        <button className='text-xs md:text-base text-white mx-4 md:px-5 py-3 cursor-pointer rounded-md bg-yellow-500 px-8 w-48'>Get A Quote</button>
                    </form>
                </div>
            </div>
            <div className='w-full md:w-1/4 h-full bg-white p-4 flex flex-col justify-center items-center'>
                <img src={logo} alt="Maruti Rotoprinters Logo" className="mx-auto mb-4 w-24 md:w-32 h-24 md:h-32 object-contain animate-fade-in" />
                <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold mb-4 text-center animate-slide-in-left">Maruti Rotoprinters</h1>
                <p className="text-base md:text-lg lg:text-2xl mb-8 text-center animate-slide-in-left">Unveiling Excellence in Flexible Packaging</p>
                <div className="m-4 md:m-8">
                    <img src={pack} alt="Machine" className="max-h-48 md:max-h-screen max-w-full md:max-w-3xl mx-auto" />
                </div>
            </div>
        </div>
    )
}

export default Homepage2
