import React from 'react';


function Gallery() {

  return (
   <div className=''>
   <h1 className=''></h1>
   <p className=''></p>
     <div className=''>
     
     </div>
   </div>
  );
}

export default Gallery;
