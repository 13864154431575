import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/Logo_maruti_rotoprinter.png';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <nav className="bg-white w-full mx-auto lg:px-20 z-20">
            <div className="flex justify-between items-center p-4">
                <div className="flex items-center space-x-3">
                    <img src={logo} className="h-16 lg:h-24" alt="Logo for navigation" />
                </div>
                <div className="hidden lg:flex lg:items-center lg:space-x-6">
                    <ul className="flex space-x-2">
                        <li className="text-black text-xl font-semibold relative group">
                            <Link to='/' className="flex items-center px-1">
                                HOME
                                <span className="absolute bottom-0 left-0 w-full border-b-2 border-transparent transition-transform duration-300 group-hover:border-black"></span>
                            </Link>
                        </li>
                        <li className="group relative">
                            <div className="text-xl flex items-center text-black text-center font-semibold px-1 cursor-pointer" onClick={toggleDropdown}>
                                <span className="ml-3">COMPANY PROFILE</span>
                                <span className="ml-auto shrink-0 transition-transform duration-300 transform rotate-0 lg:group-hover:rotate-180">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                            <div className={`absolute left-1/2 transform -translate-x-1/2 ${dropdownOpen ? 'block' : 'hidden'} bg-white shadow-lg rounded-lg mt-2 w-96 transition-all duration-300 ease-in-out z-20`}>
                                <nav className="flex flex-col p-2">
                                    <Link to="/about" onClick={toggleDropdown} className="text-black text-lg font-semibold py-1 text-center">
                                        ABOUT US
                                    </Link>
                                    <Link to="/company_profile" onClick={toggleDropdown} className="text-black text-lg font-semibold py-1 text-center">
                                        STRENGTH AND INFRASTRUCTURE
                                    </Link>
                                </nav>
                            </div>
                        </li>
                        <li className="text-black text-xl font-semibold relative group">
                            <Link to='/product' className="flex items-center px-1">
                                PRODUCTS
                                <span className="absolute bottom-0 left-0 w-full border-b-2 border-transparent transition-transform duration-300 group-hover:border-black"></span>
                            </Link>
                        </li>
                        <li className="text-black text-xl font-semibold relative group">
                            <Link to='/contact' className="flex items-center px-1">
                                CONTACT US
                                <span className="absolute bottom-0 left-0 w-full border-b-2 border-transparent transition-transform duration-300 group-hover:border-black"></span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="lg:hidden">
                    <button
                        onClick={toggleMenu}
                        className="text-black focus:outline-none"
                        aria-label={menuOpen ? 'Close menu' : 'Open menu'}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-6 w-6 ${menuOpen ? 'hidden' : 'block'}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6h16M4 12h16m-7 6h7"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-6 w-6 ${menuOpen ? 'block' : 'hidden'}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <ul className={`lg:hidden ${menuOpen ? 'block' : 'hidden'} bg-white text-black text-center justify-center flex flex-col items-center`}>
                <li className="text-black text-xl font-semibold py-2 border-t border-b">
                    <Link to='/' onClick={toggleMenu} className="block px-4 py-2">HOME</Link>
                </li>
                <li className="relative group z-20 w-380 ">
                    <div className="text-xl flex items-center text-black border-b py-2 text-center font-semibold px-1 cursor-pointer " onClick={toggleDropdown}>
                        <span className="">COMPANY PROFILE</span>
                        <span className="ml-auto shrink-0 transition-transform duration-300 transform rotate-0 lg:group-hover:rotate-180">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </span>
                    </div>
                    <div className={`absolute left-1/2 transform -translate-x-1/2 ${dropdownOpen ? 'block' : 'hidden'} bg-white shadow-lg rounded-lg mt-2 w-96 transition-all duration-300 ease-in-out z-20`}>
                        <nav className="flex flex-col p-2">
                            <Link to="/about" onClick={toggleMenu} className="text-black text-lg font-semibold py-1">ABOUT US</Link>
                            <Link to="/company_profile" onClick={toggleMenu} className="text-black text-lg font-semibold py-1">STRENGTH AND INFRASTRUCTURE</Link>
                        </nav>
                    </div>
                </li>
                <li className="text-black text-xl font-semibold py-2 border-b">
                    <Link to='/product' onClick={toggleMenu} className="block px-4 py-2">PRODUCTS</Link>
                </li>
                <li className="text-black text-xl font-semibold py-2 border-b">
                    <Link to='/contact' onClick={toggleMenu} className="block px-4 py-2">CONTACT US</Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
