import React from 'react';
import bgvideo from '../../../img/MARUTI ROTOPRINTERS.mp4';

function Abouthome() {
    return (
        <div className="flex flex-col md:flex-row pb-20">
            <div className="md:w-1/2 flex items-center justify-center h-1/2 md:h-full p-6 animate-slideInRight">
                <video
                    autoPlay
                    loop
                    muted
                    className="w-full h-auto mt-10 md:h-full object-cover rounded-lg shadow-lg"
                >
                    <source
                        src={bgvideo}
                        type="video/mp4"
                    />
                </video>
            </div>
            <div className="md:w-1/2 flex flex-col justify-center h-1/2 md:h-full p-6 md:p-10 animate-slideInLeft">
                <div>
                    <p className="text-lg md:text-xl font-normal text-gray-800">Welcome to</p>
                    <h1 className="text-2xl md:text-4xl font-extrabold mt-2 text-gray-900">Maruti Rotoprinters!</h1>
                </div>
                <div className="mt-6 bg-white p-6">
                    <p className="text-base md:text-lg mb-4 font-medium text-gray-800">
                        Established in 2024, we specialize in manufacturing high-quality rotogravure printed laminated pouches and rolls for packaging.
                    </p>
                    <p className="text-base md:text-lg mb-4 font-medium text-gray-800">
                        We cater to a diverse range of industries, producing all types of food-grade and general-purpose flexible packaging solutions. Maruti Rotoprinters are associated with various reputed names in the food and food-packaging industries, as well as providing packaging materials for some state government departments.
                    </p>
                    <p className="text-base md:text-lg mb-4 font-medium text-gray-800">
                        Founded by Pranil Mehta and Pritesh Saundatte, Maruti Rotoprinters brings together a team of highly educated professionals with extensive experience in the packaging industry.
                    </p>
                    <p className="text-base md:text-lg font-medium text-gray-800">
                        Discover the difference with Maruti Rotoprinters – where quality meets innovation.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Abouthome;
