import React from 'react';
import bgimage from '../../img/Footer.png';
import logo from '../../img/Logo_maruti_rotoprinter.png';
import { IoMdCall, IoLogoWhatsapp } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaInstagramSquare, FaFacebook } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { ImOffice } from "react-icons/im";

function Footer() {
  return (
    <footer className="relative bg-cover bg-center py-12" style={{ backgroundImage: `url(${bgimage})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative container mx-auto px-4">
        <div className="flex flex-wrap text-white">
          <div className="w-full lg:w-6/12 px-4 mb-6 lg:mb-0">
            <img src={logo} alt="Company Logo" className="h-32 mb-4" />
            <div className="flex items-center space-x-4">
              <a href="tel:+919730586756" className="inline-block rounded-full bg-white p-3 text-xl font-medium text-[#299B46] shadow-md transition-transform transform hover:scale-125 focus:outline-none">
                <IoCallSharp />
              </a>
              <a href="mailto:marutirotoprinters@gmail.com" className="inline-block rounded-full bg-white p-3 text-xl font-medium text-[#E04D3B] shadow-md transition-transform transform hover:scale-125 focus:outline-none">
                <MdEmail />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="inline-block rounded-full bg-white p-3 text-xl font-medium text-[#F62468] shadow-md transition-transform transform hover:scale-125 focus:outline-none">
                <FaInstagramSquare />
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="inline-block rounded-full bg-white p-3 text-xl font-medium text-[#4064AC] shadow-md transition-transform transform hover:scale-125 focus:outline-none">
                <FaFacebook />
              </a>
              <a href="https://wa.me/919730586756" target="_blank" rel="noopener noreferrer" className="inline-block rounded-full bg-white p-3 text-xl font-medium text-[#4FC65C] shadow-md transition-transform transform hover:scale-125 focus:outline-none">
                <IoLogoWhatsapp />
              </a>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-sm font-semibold mb-2">Useful Links</span>
                <ul className="list-unstyled">
                  <li className="pb-2">
                    <Link to='/' className="text-white hover:text-yellow-200 font-semibold text-sm">Home</Link>
                  </li>
                  <li className="pb-2">
                    <Link to='/about' className="text-white hover:text-yellow-200 font-semibold text-sm">About Us</Link>
                  </li>
                  <li className="pb-2">
                    <Link to='/company_profile' className="text-white hover:text-yellow-200 font-semibold text-sm">Strength and Infrastructure</Link>
                  </li>
                  <li className="pb-2">
                    <Link to='/product' className="text-white hover:text-yellow-200 font-semibold text-sm">Product</Link>
                  </li>
                  <li className="pb-2">
                    <Link to='/contact' className="text-white hover:text-yellow-200 font-semibold text-sm">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-sm font-semibold mb-2">Address</span>
                <ul className="list-unstyled">
                  <li className=" items-center pb-2">
                    <ImOffice className="text-white hover:text-yellow-200 text-center mr-2 h-8 ml-10" />
                    <span>196/2, near RTO office, Vasantdada Industrial Estate, Sangli, Sangli Miraj Kupwad, Maharashtra 416416</span>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-sm font-semibold mb-2">Contact</span>
                <ul className="list-unstyled">
                  <li className="flex items-center pb-2">
                    <IoMdCall className="text-white mr-2" />
                    <span><a href="tel:+919730586756" className="hover:text-yellow-200">+91 9730586756</a></span>
                  </li>
                 
                  <li className=" items-center pb-2">
                    <p className='flex items-center'><HiMail className="text-white mr-2" /><span>Email</span> </p>                   
                    <span>marutirotoprinters@gmail.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-white" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
          <div className="w-full text-center px-4">
            <div className="text-sm text-white font-semibold py-1">
              &copy; {new Date().getFullYear()} Maruti Rotoprinters. All Rights Reserved. Developed by{' '}
              <a href="https://www.businessmantra.info/index.php" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-500 transition">
                Business Mantra
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
