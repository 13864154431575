import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import CompanyProfile from './components/Services/CompanyProfile';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Gallary from './components/Gallary/Gallary';
import Product from './components/Product/Product';

function App() {
  return (
    <div className="App">
      <Router>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/company_profile' element={<CompanyProfile/>} />
          <Route path='/product' element={<Product/>} />

        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
